import Head from 'next/head'
import '@fontsource/inter'
import '../styles/globals.css'
import { AppProps } from 'next/app'
import ChatWidget from '../shared/components/ChatWidget'
import TwoButtonNotification from '../shared/components/atoms/TwoButtonNotification'
import { useEffect, useState } from 'react'
import ErrorBoundary from '../shared/components/ErrorBoundary'
import { SWRConfig } from 'swr'

// import * as ga from '../shared/lib/ga'

export default function MyApp({ Component, pageProps }: AppProps) {
  // const router = useRouter();
  const [showChatNotification, setShowChatNotification] = useState(false)
  const [chatNotificationMessage, setChatNotificationMessage] = useState('Erik from Rune wants to hear from you!')
  const [showFrontendComponents, setShowFrontendComponents] = useState(false)
  useEffect(() => {
    setShowFrontendComponents(true)
  })
  // useEffect(() => {
  //   const handleRouteChange = (url: any) => {
  //     ga.pageview(url)
  //   }
  //   //When the component is mounted, subscribe to router changes
  //   //and log those page views
  //   router.events.on('routeChangeComplete', handleRouteChange)

  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange)
  //   }
  // }, [router.events])
  return (
    <SWRConfig value={{ fetcher: (url) => fetch(url).then((res) => res.json()) }}>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5" />
        <meta />
        <meta
          name="description"
          content="Trusted local computer repair, business IT, and website / software development. Get your computer fixed, business online, and software running."
        />
        <meta
          name="keywords"
          content="IT Service, Computer Repair, Business IT, Website Development, Software Development, Mac, Apple, Laptop, Desktop"
        />
        <title>Rune - Technology Services</title>
        <link rel="manifest" href="/manifest.json" />
        <link href="/icons/favicon.png" rel="icon" type="image/png" sizes="16x16" />
        <link href="/icons/favicon.png" rel="icon" type="image/png" sizes="32x32" />
        <link rel="apple-touch-icon" href="/apple-icon.png"></link>
        <meta name="theme-color" content="#317EFB" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-202321882-1"></script>
        <script
          dangerouslySetInnerHTML={
            process.env.NODE_ENV === 'production'
              ? {
                  __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
                }
              : undefined
          }
        />
      </Head>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
      {showFrontendComponents ? (
        <>
          <ChatWidget
            onReceiveFirstChat={setShowChatNotification}
            setChatNotificationMessage={setChatNotificationMessage}
          />
          <TwoButtonNotification
            title="Erik would like to chat with you!"
            description={chatNotificationMessage}
            buttonOneText="Accept"
            buttonTwoText="Decline"
            buttonOneAction={async () => {
              ;(await import('react-chat-widget')).toggleWidget()
              setShowChatNotification(false)
            }}
            buttonTwoAction={() => setShowChatNotification(false)}
            show={showChatNotification}
            setShow={setShowChatNotification}
          />
        </>
      ) : (
        ''
      )}
    </SWRConfig>
  )
}
