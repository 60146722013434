export function throttle(func: any, wait = 100) {
  let timer: any = null
  return function (this: any, ...args: any) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args)
        timer = null
      }, wait)
    }
  }
}

export function noop() {
  return
}

export function alertFn(message: any) {
  return () => alert(message)
}

export function getPagination(page: number, size: number) {
  page -= 1 // page starts from 1 in UI but is really 0 in backend
  const limit = size ? +size : 3
  const from = page ? page * limit : 0
  const to = page ? from + size - 1 : size - 1

  return { from, to }
}

export function titleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
