import { throttle } from './helpers'

const GREATER_THAN_ONE_ELEMENT_FOUND = 'Draggable: More than one element has been found with this selector.'
const Draggable = class Draggable {
  selector: string
  pos1: number = 0
  pos2: number = 0
  pos3: number = 0
  pos4: number = 0
  onDragStart: any
  constructor({ selector: s, handle: h, onDragStart = () => {} }: any) {
    this.selector = s
    this.onDragStart = onDragStart
    const el = document.querySelector(s)
    // const greaterThanOne = $(s).length > 1;
    // greaterThanOne ? console.warn(GREATER_THAN_ONE_ELEMENT_FOUND)
    //   : _.noop();
    const handle = document.querySelector(h)

    if (handle) {
      // if present, the header is where you move the DIV from:
      handle.onmousedown = (e: any) => this.dragMouseDown(e)
    } else if (el) {
      // otherwise, move the DIV from anywhere inside the DIV:
      el.onmousedown = (e: any) => this.dragMouseDown(e)
    } else {
      console.warn('Draggable: No element found with this selector.')
    }
  }

  elementDrag(e: any) {
    const event = e || window.event
    event.preventDefault()
    const el = <HTMLElement>document.querySelector(this.selector)!
    // calculate the new cursor position:
    this.pos1 = this.pos3 - e.clientX
    this.pos2 = this.pos4 - e.clientY
    this.pos3 = e.clientX
    this.pos4 = e.clientY
    // set the element's new position:
    el.style.top = `${el.offsetTop - this.pos2}px`
    el.style.left = `${el.offsetLeft - this.pos1}px`
    // el.style.right = `${el.offsetRight - this.pos1}px`;
  }

  dragMouseDown(e: any) {
    this.onDragStart()
    // get the mouse cursor position at startup:
    this.pos3 = e.clientX
    this.pos4 = e.clientY
    // call a whenever the cursor moves:
    const onMouseMoveListenerThrottled = throttle((ev: any) => this.elementDrag(ev), 20)
    const removeOnMouseMoveListenerThrottled = () =>
      window.removeEventListener('mousemove', onMouseMoveListenerThrottled)
    window.addEventListener('mousemove', onMouseMoveListenerThrottled)
    window.addEventListener('mouseup', removeOnMouseMoveListenerThrottled)
  }
}

export default Draggable
