const PHONE_SUPPORT = '(530) 871-9422'
const PHONE_SUPPORT_LINK = '5308719422'
const EMAIL_SUPPORT = 'support@rune.tech'
const BUSINESS_IT_EMAIL = 'business@rune.tech'
const MAIL_ADDRESS = '3419 Hackamore Lane, Chico, CA, 95973'
const RUNE_EMAIL = 'erik@rune.tech'
const FACEBOOK_URL = 'https://www.facebook.com/RuneChico'
const STRIPE_DASHBOARD_URL = 'https://dashboard.stripe.com/'
const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
const PHONE_REGEX = /^\W?\d*?\W*?(?<area>\d{3})\W*?(?<group1>\d{3})\W*?(?<group2>\d{4})\W*?$/
const MARKETING_SITE_URL = process.env.NODE_ENV === 'production' ? 'https://www.rune.tech' : 'http://localhost:3003'
const BLOG_SITE_URL = process.env.NODE_ENV === 'production' ? 'https://blog.rune.tech' : 'http://localhost:3004'
const APP_URL = process.env.NODE_ENV === 'production' ? 'https://app.rune.tech' : 'http://localhost:3005'
const STORE_URL = process.env.NODE_ENV === 'production' ? 'https://store.rune.tech' : 'http://localhost:3006'
const CHAT_SERVER_URL = process.env.NODE_ENV === 'production' ? 'chat.rune.tech' : 'localhost:4001'
const BANNER_TITLES = [
  {
    mobileTitle: "Did you know we're hiring 🎉!",
    title: "Did you know we're hiring 🎉!",
    href: `${MARKETING_SITE_URL}/careers`,
  },
  {
    mobileTitle: 'Welcome to Rune 🎉!',
    title: 'What can we help you with today?',
    href: `${MARKETING_SITE_URL}/services`,
  },
  {
    mobileTitle: 'We love Web Development 🖥️!',
    title: 'We love Web Development 🖥️!',
    href: `${MARKETING_SITE_URL}/services/develop/web`,
  },
  {
    mobileTitle: '10+ years in business 🎉!',
    title: 'Celebrating 10+ years in business 🎉!',
    href: `${MARKETING_SITE_URL}/services`,
  },
  {
    mobileTitle: "Why did the invisible man turn down the job offer? He couldn't see himself doing the job.",
    title: "Why did the invisible man turn down the job offer? He couldn't see himself doing the job.",
    href: `${MARKETING_SITE_URL}/services`,
  },
  {
    mobileTitle: '🔧 Computer repair is where we began 🔧',
    title: '🔧 Computer repair is where we began 🔧',
    href: `${MARKETING_SITE_URL}/repair`,
  },
  {
    mobileTitle: 'We remove viruses 🤢! ',
    title: 'We remove viruses 🤢! ',
    href: `${MARKETING_SITE_URL}/repair/virus-removal`,
  },
  {
    mobileTitle: 'Rune offers Business IT Services!',
    title: 'Can we help you with any Business IT services today?',
    href: `${MARKETING_SITE_URL}/business-it`,
  },
  {
    mobileTitle: `Did you know Rune offers Managed IT 🤝?`,
    title: `Did you know we can help with Managed IT? `,
    href: `${MARKETING_SITE_URL}/business-it/managed-it`,
  },
  {
    mobileTitle: 'Rune specializes in custom software solutions 💡!',
    title: "Rune specializes in custom software solutions, what's your idea 💡?",
    href: `${MARKETING_SITE_URL}/develop`,
  },
]

module.exports = {
  PHONE_SUPPORT,
  PHONE_SUPPORT_LINK,
  EMAIL_SUPPORT,
  BUSINESS_IT_EMAIL,
  MAIL_ADDRESS,
  RUNE_EMAIL,
  FACEBOOK_URL,
  EMAIL_REGEX,
  PHONE_REGEX,
  MARKETING_SITE_URL,
  BLOG_SITE_URL,
  CHAT_SERVER_URL,
  BANNER_TITLES,
  APP_URL,
  STORE_URL,
  STRIPE_DASHBOARD_URL,
}
