import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { noop } from '../../lib/helpers'
import NotificationRegion from './NotificationRegion'
import Image from 'next/image'

export default function Example({
  title = 'Emilia Gates',
  description = 'Sent you an invite to connect.',
  buttonOneText = 'Accept',
  buttonTwoText = 'Decline',
  buttonOneAction = noop,
  buttonTwoAction = noop,
  show = false,
  setShow = noop,
}: any) {
  return (
    <>
      <NotificationRegion>
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <Image
                    className="h-10 w-10 rounded-full"
                    src="/assets/images/team/erik-avatar.gif"
                    alt=""
                    width={40}
                    height={40}
                  />
                </div>
                <div className="ml-3 w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">{title}</p>
                  <p className="mt-1 text-sm text-gray-500">{description}</p>
                  <div className="mt-4 flex">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm transition-colors hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      onClick={buttonOneAction}
                    >
                      {buttonOneText}
                    </button>
                    <button
                      type="button"
                      className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500  focus:ring-offset-2"
                      onClick={buttonTwoAction}
                    >
                      {buttonTwoText}
                    </button>
                  </div>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    className="inline-flex rounded-md bg-white text-gray-400 transition-colors hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </NotificationRegion>
    </>
  )
}
