import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import 'react-chat-widget/lib/styles.css'
import { io } from 'socket.io-client'
import Draggable from '../lib/draggable'
import { noop } from '../lib/helpers'
import { CHAT_SERVER_URL } from '../lib/constants'

declare global {
  interface Window {
    socketConnected: boolean
  }
}

let hasSentMessage = false
let hasReceivedMessage = false

function handleNewUserMessage(newMessage: string, socket: any, messages: any, setMessages: any) {
  hasSentMessage = true
  socket?.emit('chat', newMessage)
  messages.push({ type: 'user', text: newMessage, date: Date.now() })
  localStorage.setItem('messages', JSON.stringify(messages))
  setMessages([...messages])
}

const Widget: any = dynamic((): any => import('react-chat-widget').then((mod) => mod.Widget), { ssr: false })

function setupSocket(setIsSocketConnected: any) {
  const socket = io(`${process.env.NODE_ENV === 'production' ? 'https://' : 'http://'}${CHAT_SERVER_URL}`)
  socket.on('connect', () => {
    window.socketConnected = true
    setTimeout(() => setIsSocketConnected(true), 3000)
    socket?.emit('chat', `Now browsing: ${location.href}`)
  })
  socket.on('connect_error', () => {
    setIsSocketConnected(false)
    window.socketConnected = false
  })
  return socket
}

function handleChatResponse(
  setChatNotificationMessage: any,
  showChatNotification: any,
  addResponseMessage: any,
  messages: any,
  message: any,
  setMessages: any
) {
  const firstChat = !hasReceivedMessage && !hasSentMessage && messages.length < 2
  if (firstChat) {
    setChatNotificationMessage(`Erik says: ${message}`)
    showChatNotification(true)
  }
  hasReceivedMessage = true
  addResponseMessage(message)
  messages.push({ type: 'bot', text: message, date: Date.now() })
  localStorage.setItem('messages', JSON.stringify(messages))
  setMessages([...messages])
}

async function setupFirstMessage(
  socket: any,
  showChatNotification: any,
  setChatNotificationMessage: any,
  messages: any,
  setMessages: any
) {
  const addResponseMessage = (await import('react-chat-widget')).addResponseMessage
  if (messages.length === 0) {
    setTimeout(() => {
      const message = 'Hello there, how can I help you today?'
      addResponseMessage(message)
      messages.push({ type: 'bot', text: message, date: Date.now() })
      setMessages([...messages])
    }, 7000)
  }
  socket.on('chat', (message: any) =>
    handleChatResponse(
      setChatNotificationMessage,
      showChatNotification,
      addResponseMessage,
      messages,
      message,
      setMessages
    )
  )
}

function setupDraggable(toggleStatus: any) {
  if (toggleStatus) {
    new Draggable({
      selector: '.rcw-conversation-container',
      handle: '.rcw-header',
    })
  }
}

async function seedChatHistory(
  messages: any,
  setStartingMessageLength: any,
  setCurrentMessageLength: any,
  setMessages: any
) {
  const addResponseMessage = (await import('react-chat-widget')).addResponseMessage
  const addUserMessage = (await import('react-chat-widget')).addUserMessage

  if (messages.length) {
    const lastMessageDate = messages.at(-1).date
    const msBetweenDates = Math.abs(lastMessageDate - Date.now())
    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)
    if (hoursBetweenDates > 1) {
      setMessages([])
      messages = []
      localStorage.setItem('messages', JSON.stringify(messages))
    }
  }

  setStartingMessageLength(messages.length)
  setCurrentMessageLength(messages.length)

  messages.forEach((message: any) => {
    if (message.type === 'user') {
      addUserMessage(message.text)
    }
    if (message.type === 'bot') {
      addResponseMessage(message.text)
    }
  })
}

export default function ChatWidget({
  onReceiveFirstChat: showChatNotification = noop,
  setChatNotificationMessage = noop,
}: any) {
  const [isSocketConnected, setIsSocketConnected] = useState(false)
  const [socket, setSocket] = useState(null as any)
  const [messages, setMessages] = useState([] as any)
  const [startingMessageLength, setStartingMessageLength] = useState(0)
  const [currentMessageLength, setCurrentMessageLength] = useState(0)

  useEffect(() => {
    const messages = JSON.parse(localStorage.getItem('messages') || '[]')
    localStorage.setItem('messages', JSON.stringify(messages))
    setMessages([...messages])
    const socketResponse = setupSocket(setIsSocketConnected)
    setTimeout(
      async () => {
        await seedChatHistory(messages, setStartingMessageLength, setCurrentMessageLength, setMessages)
        try {
          setupFirstMessage(socketResponse, showChatNotification, setChatNotificationMessage, messages, setMessages)
          setSocket(socketResponse)
          setupDraggable(true)
        } catch (err) {
          console.warn(err)
        }
      },
      messages.length === 0 ? 3000 : 0
    )
  }, [])

  useEffect(() => {
    setCurrentMessageLength(messages.length)
  }, [messages])

  return (
    <>
      {isSocketConnected ? (
        <>
          <Widget
            profileAvatar={'/assets/images/team/erik-avatar.gif'}
            title="Chat with Rune"
            emojis={true}
            chatId="rcw-widget-controls"
            subtitle="Get your answer now"
            senderPlaceHolder="What's on your mind?"
            showBadge={currentMessageLength !== startingMessageLength}
            handleToggle={(toggleStatus: any) => setTimeout(() => setupDraggable(toggleStatus), 0)}
            handleNewUserMessage={(message: any) => handleNewUserMessage(message, socket, messages, setMessages)}
          />
          <div id="rcw-widget-controls" />
        </>
      ) : (
        ''
      )}
    </>
  )
}
